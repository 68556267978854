export default {
  // page top category
  news: 'news',
  event: 'event',
  // page sub category
  media: 'media',
  release: 'release',
  upcoming: 'upcoming',
  ondemand: 'ondemand',
  partner: 'partner',
  exhibition: 'exhibition',
  pastevents: 'pastevents',
  consumer: 'consumer',
};
