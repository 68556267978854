export default {
  news: 'NEWS & TOPICS',
  release: 'NEWS RELEASE',
  media: 'MEDIA',
  event: 'AppGuardイベント情報',
  upcoming: 'UPCOMING EVENT',
  ondemand: 'ONDEMAND WEB SEMINAR',
  partner: 'PARTNER-SPONSORED EVENT',
  exhibition: 'EXHIBITION',
  pastevents: 'PAST EVENTS',
  consumer: 'CONSUMER',
};
