<template>
  <section id="news">
    <div class="news_area">
      <h2>Information</h2>
      <swiper :options="swiperOptions">
        <swiper-slide v-for="post in posts" :key="post.id">
          <a :href="postDetailUrl(post)">
            <p class="day mb10">
              {{ post.date | local_datetime }}
              <span :class="post.cat.slug">
                {{ post.cat.name }}
              </span>
            </p>
            <h3>{{ post.title.rendered }}</h3>
          </a>
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  watch: {
    $route() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    fetchData: function () {
      this.reloadPosts(this.page_categories, null, { per_page: 6 });
    },
    init() {
      this.fetchData();
    },
  },
  data() {
    return {
      swiperOptions: {
        autoplay: {
          delay: 5000,
        },
        speed: 1000,
        loop: true,
        loopAdditionalSlides: 1,
        slidePerView: 1,
        spaceBetween: 20,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    };
  },
};
</script>
