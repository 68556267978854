<template>
  <dl class="archive">
    <dt>Archive</dt>
    <dd>
      <router-link :to="{ path, query: { year: 2021 } }">2021</router-link>
    </dd>
    <dd>
      <router-link :to="{ path, query: { year: 2020 } }">2020</router-link>
    </dd>
    <dd>
      <router-link :to="{ path, query: { year: 2019 } }">2019</router-link>
    </dd>
    <dd>
      <router-link :to="{ path, query: { year: 2018 } }">2018</router-link>
    </dd>
    <dd>
      <router-link :to="{ path, query: { year: 2017 } }">2017</router-link>
    </dd>
  </dl>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      default: '',
    },
  },
};
</script>
