<template>
  <div id="news">
    <template>
      <div class="inner" v-if="post.cat">
        <p id="pankuzu">
          <a href="/en/">HOME</a>
          >
          <router-link :to="{ path: '/en/news/index.html' }">{{ title }}</router-link>
          <template v-if="post.title"> > {{ post.title.rendered }}</template>
        </p>

        <section class="newslist fade-up">
          <article>
            <div class="detail">
              <p class="days">
                {{ post.date | local_datetime }}
                <span class="newsico">News Release</span>
              </p>
              <h3 v-if="post.title">{{ post.title.rendered }}</h3>
              <p class="read" v-html="toContent(post)" v-if="post.content"></p>
            </div>

            <ul class="pager">
              <li>
                <router-link v-if="prevPost" :to="{ query: { id: prevPost.id } }">
                  &#x3C; PREV
                </router-link>
              </li>
              <li>
                <router-link :to="{ path: '/en/news/index.html' }">TOP</router-link>
              </li>
              <li>
                <router-link v-if="nextPost" :to="{ query: { id: nextPost.id } }">
                  NEXT &#x3E;
                </router-link>
              </li>
            </ul>
          </article>

          <link-archive path="/en/news/index.html" />
        </section>
      </div>

      <link-area />
    </template>
  </div>
</template>

<script>
import LinkArchive from '@/components/en/LinkArchive';
import LinkArea from '@/components/en/LinkArea';

import pageCategories from '@/constants/pageCategories';
import titles from '@/constants/titles';

export default {
  components: {
    LinkArchive,
    LinkArea,
  },
  watch: {
    $route() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    pageCategory() {
      // en は news 固定
      return pageCategories.news;
    },
    title() {
      return titles[this.pageCategory];
    },
  },
  methods: {
    fetchData: function () {
      this.reloadPosts(this.page_categories, this.$route.query.id);
    },
    onPostLoaded: function () {
      this.$emit('updateHead');
    },
    init() {
      this.fetchData();
    },
  },
  head: {
    title: function () {
      return {
        inner: this.post && this.post.title ? this.post.title.rendered : '',
      };
    },
    meta: function () {
      return [
        {
          name: 'description',
          content: this.post && this.post.title ? this.post.title.rendered : '',
        },
        {
          name: 'twitter:title',
          content: this.post && this.post.title ? this.post.title.rendered : '',
        },
        {
          n: 'twitter:description',
          c: this.post && this.post.title ? this.post.title.rendered : '',
        },
      ];
    },
    link() {
      return [
        {
          rel: 'stylesheet',
          media: '(min-width: 768px)',
          href: '/css/news_pc.css',
        },
        {
          rel: 'stylesheet',
          media: '(max-width: 767px)',
          href: '/css/news_sp.css',
        },
      ];
    },
  },
};
</script>
