<template>
  <section id="news">
    <h2 class="fade-up">News &amp; Topics</h2>
    <p class="mb fade-up">Latest News</p>
    <div class="inner fade-up">
      <div class="box" v-for="post in posts" :key="post.id">
        <!-- max 4-->
        <a :href="postDetailUrl(post)">
          <div class="imgWrap">
            <img :src="catchImage(post)" :alt="post.title.rendered" />
          </div>
          <p class="day">
            {{ post.date | local_datetime }}
            <span class="newsico">News Release</span>
          </p>
          <h3>{{ post.title.rendered }}</h3>
          <div v-html="toExcerpt(post)"></div>
        </a>
      </div>
    </div>

    <p class="linkarea">
      <a href="/en/news/" class="linkbtn fa fa-angle-right">VIEW ALL</a>
    </p>
  </section>
</template>

<script>
export default {
  watch: {
    $route() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    fetchData: function () {
      this.reloadPosts(this.page_categories, null, { per_page: 4 });
    },
    init() {
      this.fetchData();
    },
  },
};
</script>
