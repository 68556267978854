<template>
  <dl class="archive">
    <dt>お知らせアーカイブ</dt>
    <dd>
      <a :href="`${path}?year=2023`">2023</a>
    </dd>
    <dd>
      <a :href="`${path}?year=2022`">2022</a>
    </dd>
    <dd>
      <a :href="`${path}?year=2021`">2021</a>
    </dd>
    <dd>
      <a :href="`${path}?year=2020`">2020</a>
    </dd>
    <dd>
      <a :href="`${path}?year=2019`">2019</a>
    </dd>
    <dd>
      <a :href="`${path}?year=2018`">2018</a>
    </dd>
    <dd>
      <a :href="`${path}?year=2017`">2017</a>
    </dd>
  </dl>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      default: '',
    },
  },
};
</script>
