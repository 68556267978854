<template>
  <!-- InstanceBeginEditable name="main" -->
  <div id="news">
    <!-- Program エントリポイント -->
    <template>
      <div v-if="post.cat" class="inner">
        <p id="pankuzu">
          <a href="/">HOME</a>
          <template v-if="isNewsCategory">
            >
            <a href="/news/index.html">{{ titles.news }}</a>
          </template>
          >
          <a :href="pathIndex">{{ title }}</a>
          <template v-if="post.title"> > {{ post.title.rendered }}</template>
        </p>

        <section class="newslist fade-up">
          <article>
            <div class="detail">
              <p class="days">
                {{ post.date | local_datetime }}
                <span :class="post.cat.slug">{{ post.cat.name }}</span>
              </p>

              <h1 v-if="post.title">{{ post.title.rendered }}</h1>

              <p v-if="post.content" class="read" v-html="toContent(post)"></p>
            </div>

            <ul class="pager" v-if="!isConsumerCategory">
              <li>
                <router-link v-if="prevPost" :to="{ query: { id: prevPost.id } }">
                  &#x3C; PREV
                </router-link>
              </li>
              <li>
                <a :href="pathTop">TOP</a>
              </li>
              <li>
                <router-link v-if="nextPost" :to="{ query: { id: nextPost.id } }">
                  NEXT &#x3E;
                </router-link>
              </li>
            </ul>
          </article>

          <link-archive v-if="!isConsumerCategory" :path="pathIndex" />
        </section>
      </div>

      <link-area />
    </template>
  </div>
  <!-- #news -->
  <!-- InstanceEndEditable -->
</template>

<script>
import LinkArchive from '@/components/ja/LinkArchive';
import LinkArea from '@/components/ja/LinkArea';

import pageCategories from '@/constants/pageCategories';
import titles from '@/constants/titles';

export default {
  components: {
    LinkArchive,
    LinkArea,
  },
  watch: {
    $route() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    category() {
      const slug = this.post.cat ? this.post.cat.slug : '';
      switch (slug) {
        case pageCategories.media:
          return pageCategories.media;
        case pageCategories.release:
          return pageCategories.release;
        case pageCategories.consumer:
          return pageCategories.consumer;
        default:
          return pageCategories.event;
      }
    },
    titles() {
      return titles;
    },
    title() {
      return titles[this.category];
    },
    pathIndex() {
      switch (this.post.cat.slug) {
        case 'online':
        case 'exhibition':
        case 'event':
          return '/event/upcoming';
        case 'ondemand':
          return '/event/ondemand';
        case 'partner':
          return '/event/partner';
        case 'pastevents':
          return '/event/pastevents';
        case 'media':
          return '/news/media.html';
        case 'release':
          return '/news/news-release.html';
        case 'consumer':
          return '/consumer/';
        default:
          // unexpected category
          return '/event/index.html';
      }
    },
    isEventCategory() {
      return this.$route.path.indexOf('/event') === 0;
    },
    isNewsCategory() {
      return this.$route.path.indexOf('/news') === 0;
    },
    isConsumerCategory() {
      return this.$route.path.indexOf('/new-consumer') === 0;
    },
    pathTop() {
      if (this.isEventCategory) {
        return '/event/index.html';
      }
      return '/news/index.html';
    },
  },
  methods: {
    fetchData: function () {
      this.reloadPosts(this.page_categories, this.$route.query.id);
    },
    onPostLoaded: function () {
      this.$emit('updateHead');
    },
    init() {
      this.fetchData();
    },
  },
  head: {
    title: function () {
      return {
        inner: this.post && this.post.title ? this.post.title.rendered : '',
      };
    },
    meta: function () {
      return [
        {
          name: 'description',
          content: this.post && this.post.title ? this.post.title.rendered : '',
        },
        {
          name: 'twitter:title',
          content: this.post && this.post.title ? this.post.title.rendered : '',
        },
        {
          n: 'twitter:description',
          c: this.post && this.post.title ? this.post.title.rendered : '',
        },
      ];
    },
    link() {
      if (this.isEventCategory) {
        return [
          {
            rel: 'stylesheet',
            media: '(min-width: 768px)',
            href: '/css/event_pc.css',
          },
          {
            rel: 'stylesheet',
            media: '(max-width: 767px)',
            href: '/css/event_sp.css',
          },
        ];
      } else {
        return [
          {
            rel: 'stylesheet',
            media: '(min-width: 768px)',
            href: '/css/news_pc.css',
          },
          {
            rel: 'stylesheet',
            media: '(max-width: 767px)',
            href: '/css/news_sp.css',
          },
        ];
      }
    },
  },
};
</script>
