// pageCategories.jsで定義したページカテゴリとそのページに表示するWordpressカテゴリをマッピングする
export default {
  // for jp
  news: 'media,release',
  release: 'release',
  media: 'media',
  event: 'online,exhibition,ondemand,partner,pastevents,event',
  upcoming: 'online,exhibition,ondemand,event',
  exhibition: 'exhibition',
  ondemand: 'ondemand',
  partner: 'partner',
  pastevents: 'pastevents',
  consumer: 'consumer',
  // for en
  enNews: 'press-en',
};
