<template>
  <!-- InstanceBeginEditable name="main" -->
  <div id="event">
    <!-- Program エントリポイント -->
    <template>
      <!-- head / -->
      <div id="head" class="event_bg">
        <div class="inner">
          <h1 class="eventtop">イベント情報</h1>
          <p>
            Blue Planet-worksは既に導入していただいている企業様、
            <br>導入をご検討中の企業様に向け、様々なイベントを実施しています。
          </p>
        </div>
      </div>

      <!--カルーセル-->
      <section id="pickup">
        <div class="pickup_area">
          <swiper :options="swiperOptions">
            <swiper-slide v-for="post in latestPosts" :key="post.id">
              <a :href="postDetailUrl(post)">
                <div class="p_e_box">
                  <div class="thum"><img :src="catchImage(post)" :alt="post.title.rendered" /></div>
                  <div class="seminor_info">
                    <p class="event_cat">
                      <span :class="post.cat.slug">{{ post.cat.name }}</span>
                    </p>
                    <h3>{{ post.title.rendered }}</h3>
                    <div class="e_content">
                      <div class="point">
                        <h4>ここがポイント</h4>
                        <div v-html="post.acf.point"></div>
                      </div>
                      <div class="speaker">
                        <h4>登壇者</h4>
                        <div v-html="post.acf.speakers"></div>
                      </div>
                    </div>
                    <p v-if="post.acf.meeting_date" class="date">{{ post.acf.meeting_date }}</p>
                  </div>
                </div>
              </a>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </section>

      <div class="inner" id="link-top">
        <p class="read_top">
          Blue
          Planet-works、およびパートナー企業が主催しているイベントをカテゴリー毎にご確認いただけます。
        </p>
        <!-- カテゴリリンク -->
        <div class="event_cat_link">
          <ul>
            <li v-for="tab in tabs" :key="tab.category">
              <button
                v-bind:class="{ active: currentTab === tab.category }"
                v-on:click="setTabCategory(tab.category)"
                v-bind:disabled="currentTab === tab.category"
              >
                {{ tab.name }}
              </button>
            </li>
          </ul>
        </div>

        <!-- section / -->

        <div class="tab_content">
          <section class="newslist">
            <article>
              <div class="box" v-for="post in postsInCategory(currentTab)" :key="post.id">
                <a :href="`/event/detail.html?id=${post.id}`">
                  <div class="thum"><img :src="catchImage(post)" :alt="post.title.rendered" /></div>
                  <p class="days top">
                    <span :class="post.cat.slug">{{ post.cat.name }}</span>
                  </p>
                  <h3>{{ post.title.rendered }}</h3>
                  <p class="point_tit">ここがポイント</p>
                  <p class="point" v-html="post.acf.point"></p>
                  <p v-if="post.acf.meeting_date" class="date">{{ post.acf.meeting_date }}</p>
                </a>
              </div>
            </article>
            <p class="case_btn ">
              <a :href="`/event/${currentTab}`"><i class="fa fa-plus mr05"></i>もっと見る</a>
            </p>
          </section>
        </div>
        <p class="info_message">
          セミナーに関するお問い合わせは以下のお問い合わせボタンからお問い合わせフォームを開いて、ご入力ください。
        </p>
        <p class="info_link"><a href="/contact/">お問い合わせ</a></p>
      </div>

      <link-area />
    </template>
  </div>
  <!-- #news -->
  <!-- InstanceEndEditable -->
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import LinkArea from '@/components/ja/LinkArea';

import wpPostCategories from '@/constants/wpPostCategories';

export default {
  components: {
    LinkArea,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        autoplay: {
          delay: 5000,
        },
        speed: 1000,
        loop: true,
        loopAdditionalSlides: 1,
        slidePerView: 1,
        spaceBetween: 20,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      currentTab: 'upcoming',
      tabs: [
        { category: 'upcoming', name: '今後受講・参加できるイベント' },
        { category: 'partner', name: 'パートナー主催イベント' },
        { category: 'ondemand', name: 'オンデマンドWEBセミナー' },
        { category: 'exhibition', name: '展示会' },
        { category: 'pastevents', name: '過去のイベント' },
      ],
    };
  },
  watch: {
    $route() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  updated() {
    // jQuery との統合
    const options = {
      byRow: false,
    };
    global.$('#event .newslist .box h3').matchHeight(options);
    global.$('#event .newslist .box .point').matchHeight(options);
    global
      .$('.pickup_area .swiper-container .swiper-wrapper .swiper-slide a .p_e_box')
      .matchHeight();
  },
  methods: {
    fetchData: function () {
      this.reloadPosts(this.page_categories);
    },
    init() {
      this.fetchData();
    },
    setTabCategory(category) {
      this.currentTab = category;
    },
    // pageCategories から categoryId[] を取得
    categoryIds(category) {
      return wpPostCategories[category]
        .split(',')
        .map((slug) => this.categoryId(slug))
        .filter((x) => x);
    },
    // slug から categoryId を取得
    categoryId(slug) {
      const category = this.categoryData.find((cat) => cat.slug === slug);
      return category ? category.id : undefined;
    },
    // pageCategories で投稿をフィルタ
    postsInCategory(category) {
      return this.posts
        .filter((post) => this.categoryIds(category).some((id) => post.categories.includes(id)))
        .slice(0, 6);
    },
  },
  computed: {
    // pastevents を除くイベントカテゴリの最新の投稿
    latestPosts() {
      const excludeId = this.categoryId(wpPostCategories.pastevents);
      return this.posts.filter((post) => !post.categories.includes(excludeId)).slice(0, 3);
    },
  },
  head: {
    link: [
      {
        rel: 'stylesheet',
        media: '(min-width: 768px)',
        href: '/css/event_pc.css',
      },
      {
        rel: 'stylesheet',
        media: '(max-width: 767px)',
        href: '/css/event_sp.css',
      },
      {
        rel: 'stylesheet',
        href: '/css/n_products.css',
      },
    ],
  },
};
</script>
