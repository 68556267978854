import axios from 'axios';
import moment from 'moment';

import wpPostCategories from '@/constants/wpPostCategories';

// axios.defaults.withCredentials = true;
moment.locale('ja');

export default {
  data: function () {
    return {
      categories: {},
      catDict: {},
      categories_ids: {},
      // 全カテゴリを API から取得して保持
      categoryData: [],
      nextPost: null,
      prevPost: null,
      current_page: 1,
      total_pages: 1,
      post_counts: 0,
      posts: [],
      post: {},
    };
  },
  filters: {
    local_datetime: function (date, format) {
      format = format || 'YYYY.MM.DD';
      return moment(date).format(format);
    },
  },
  computed: {
    page_categories() {
      const path = this.$route.path;
      // for jp top page
      let categories = 'release,media,online,exhibition,partner';
      if (path.includes('/event/upcoming')) {
        categories = wpPostCategories.upcoming;
      } else if (path.includes('/event/ondemand')) {
        categories = wpPostCategories.ondemand;
      } else if (path.includes('/event/partner')) {
        categories = wpPostCategories.partner;
      } else if (path.includes('/event/exhibition')) {
        categories = wpPostCategories.exhibition;
      } else if (path.includes('/event/pastevents')) {
        categories = wpPostCategories.pastevents;
      } else if (path.includes('/event')) {
        categories = wpPostCategories.event;
      } else if (path.includes('/news/media.html')) {
        categories = wpPostCategories.media;
      } else if (path.includes('release.html')) {
        categories = wpPostCategories.release;
      } else if (path.includes('/en')) {
        categories = wpPostCategories.enNews;
      } else if (path.includes('/news')) {
        categories = wpPostCategories.news;
      } else if (path.includes('/new-consumer')) {
        categories = wpPostCategories.consumer;
      }
      return categories.split(',');
    },
  },
  methods: {
    getQuery: function (year) {
      if (year) {
        let after = parseInt(year);
        let before = after + 1;
        after = after.toString() + '-01-01T00:00:00';
        before = before.toString() + '-01-01T00:00:00';
        return { after: after, before: before };
      }
      return {};
    },
    getPostCategories: function (post) {
      const vm = this;
      if (post && post.categories && this.catDict) {
        return post.categories.map((id) => vm.catDict[id]).filter((c) => c);
      }
      return [];
    },
    getPostCategory: function (post) {
      const cats = this.getPostCategories(post);
      return cats.length > 0 ? cats[0] : null;
    },
    reloadPosts: async function (slugs, id, query) {
      await this.setCategoryData(slugs);
      if (id) {
        this.getPost(id);
      } else {
        this.loadPosts(this.categories_ids, null, query);
      }
    },
    getPost: function (id) {
      const vm = this;
      const params = {
        _embed: '',
      };
      const url = '/wordpress/wp-json/wp/v2/posts/' + id;
      axios
        .get(url, {
          params: params,
        })
        .then(function (res) {
          res.data.cat = vm.getPostCategory(res.data);
          vm.post = res.data;
          // console.log(vm.post);
          vm.onPostLoaded();
          vm.getNextPost(vm.post);
          vm.getPrevPost(vm.post);
        });
    },
    setCategoryData: async function (slugs) {
      const vm = this;
      const url = '/wordpress/wp-json/wp/v2/categories';
      await axios
        .get(url, {
          // 全カテゴリを取得する
          params: { per_page: 100 },
        })
        .then(function (res) {
          // すべてのカテゴリ情報を収容する
          vm.categoryData = res.data;
          // 指定の slug のカテゴリ id
          vm.categories = res.data.filter((cat) => slugs.includes(cat.slug)).map((cat) => cat);
          vm.categories_ids = vm.categories.map((cat) => cat.id);
          vm.catDict = vm.categories.reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
          }, {});
        });
    },
    onPostLoaded: function () {},
    loadPosts: function (categoriesIds, name, query) {
      // console.log("query..", query)
      const vm = this;
      name = name || 'posts';
      query = query || {};
      const params = Object.assign(
        {
          _embed: '',
          page: this.current_page,
          per_page: 100,
        },
        query
      );
      // console.log('----', params, query)
      params.categories = categoriesIds.join(',');
      const url = '/wordpress/wp-json/wp/v2/posts';
      axios
        .get(url, {
          params: params,
        })
        .then(function (res) {
          // カテゴリオブジェクトを `cat` で追加
          vm[name] = res.data.map((p) => {
            p.cat = vm.getPostCategory(p);
            return p;
          });
          vm.total_pages = res.headers['x-wp-totalpages'];
          vm.post_counts = res.headers['x-wp-total'];
        });
    },
    // wordpress の内部リンクをサイト内部のリンクに変換
    postDetailUrl: function (post) {
      const slug = post.cat.slug;
      let path = '/news/detail.html';
      if (slug == 'techblog') {
        path = '/technology/detail.html';
      } else if (wpPostCategories.event.includes(slug)) {
        path = '/event/detail.html';
      } else if (slug == 'press-en') {
        path = '/en/news/detail.html';
      }
      const url = path + '?id=' + post.id;
      return url;
    },
    toExcerpt: function (post) {
      if (post && post.excerpt) {
        const src = post.excerpt.rendered;
        const el = document.createElement('div');
        el.innerHTML = src;
        const anchors = el.getElementsByTagName('a');
        for (let i = 0; i < anchors.length; i++) {
          if (anchors[i].href.indexOf('/wordpress/') >= 0) {
            // .link-more クラスで非表示にしている
            // TODO: SPA 内部遷移に対応させる
            anchors[i].href = this.postDetailUrl(post);
          }
        }
        return el.innerHTML;
      }
      return '';
    },
    toContent: function (post) {
      if (post && post.excerpt) {
        const src = post.content.rendered;
        const el = document.createElement('div');
        el.innerHTML = src;
        const anchors = el.getElementsByTagName('a');
        for (let i = 0; i < anchors.length; i++) {
          const url = anchors[i].href;
          if (!/\.(gif|jpg|jpeg|tiff|png)$/i.test(url) && url.indexOf('/wordpress/') >= 0) {
            anchors[i].href = this.postDetailUrl(post);
          }
        }
        return el.innerHTML;
      }
      return '';
    },
    getNextPost: function (post) {
      const vm = this;
      const params = {
        _embed: '',
        after: post.date,
        order: 'asc',
      };
      params.categories = this.categories_ids.join(',');
      const url = '/wordpress/wp-json/wp/v2/posts';
      axios
        .get(url, {
          params: params,
        })
        .then(function (res) {
          vm.nextPost = res.data.length > 0 ? res.data[0] : null;
        });
    },
    getPrevPost: function (post) {
      const vm = this;
      const params = {
        _embed: '',
        before: post.date,
        order: 'desc',
      };
      params.categories = this.categories_ids.join(',');
      const url = '/wordpress/wp-json/wp/v2/posts';
      axios
        .get(url, {
          params: params,
        })
        .then(function (res) {
          vm.prevPost = res.data.length > 0 ? res.data[0] : null;
        });
    },
    catchImage: function (post) {
      try {
        const media = post['_embedded']['wp:featuredmedia'];
        const res = media[0]['media_details']['sizes']['medium']['source_url'];
        return res;
      } catch (e) {
        return '/img/news/img_news4.jpg';
      }
    },
  },
};
