import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueHead from 'vue-head';

import Wordpress from './mixins/wordpress';

Vue.use(VueHead, {
  separator: '|',
});
Vue.mixin(Wordpress);

Vue.config.productionTip = process.env.NODE_ENV !== false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
