<template>
  <div id="news">
    <template>
      <div id="head" class="news1">
        <div class="inner">
          <h2>
            <span>Blue Planet-works</span>
            <br />NEWS &amp; TOPICS
          </h2>
        </div>
      </div>

      <div class="inner" id="link-top">
        <p id="pankuzu"><a href="/en/">HOME</a> > NEWS &amp; TOPICS</p>
        <section class="newslist fade-up">
          <article>
            <p class="links">
              <a href="https://www.appguard.us/press/" target="_blank"
                >Click HERE for AppGuard, Inc. press release in US.</a
              >
            </p>
            <div class="box" v-for="post in posts" :key="post.id">
              <router-link :to="{ path: '/en/news/detail.html', query: { id: post.id } }">
                <p class="days">
                  {{ post.date | local_datetime }}
                  <span class="newsico">News Release</span>
                </p>
                <h3>{{ post.title.rendered }}</h3>
                <div class="read" v-html="toExcerpt(post)"></div>
              </router-link>
            </div>
          </article>

          <link-archive />
        </section>
      </div>

      <link-area />
    </template>
  </div>
  <!-- #news -->
</template>

<script>
import LinkArchive from '@/components/en/LinkArchive';
import LinkArea from '@/components/en/LinkArea';

import pageCategories from '@/constants/pageCategories';
import titles from '@/constants/titles';

export default {
  components: {
    LinkArchive,
    LinkArea,
  },
  watch: {
    $route() {
      this.init();
    },
    title() {
      this.$emit('updateHead');
    },
  },
  mounted() {
    this.init();
  },
  updated() {
    // jQuery との統合
    global.$('.box').matchHeight({
      byRow: false,
    });
  },
  methods: {
    fetchData: function () {
      this.reloadPosts(this.page_categories, null, this.getQuery(this.$route.query.year));
    },
    init() {
      this.fetchData();
    },
  },
  computed: {
    pageCategory() {
      // en は news 固定
      return pageCategories.news;
    },
    title() {
      return titles[this.pageCategory];
    },
  },
  head: {
    title() {
      return {
        inner: this.title,
      };
    },
    link() {
      return [
        {
          rel: 'stylesheet',
          media: '(min-width: 768px)',
          href: '/css/news_pc.css',
        },
        {
          rel: 'stylesheet',
          media: '(max-width: 767px)',
          href: '/css/news_sp.css',
        },
      ];
    },
  },
};
</script>
