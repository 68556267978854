<template>
  <!-- InstanceBeginEditable name="main" -->
  <div :id="mainElementId">
    <!-- Program エントリポイント -->
    <template>
      <!-- head / -->
      <div id="head" class="news1">
        <div class="inner">
          <h1>
            <span>Blue Planet-works</span>
            <br />{{ title }}
          </h1>
        </div>
      </div>

      <div id="link-top" class="inner">
        <p id="pankuzu">
          <a href="/">HOME</a>
          <template v-if="pageSubCategory">
            >
            <a :href="pathCategoryTop">{{ titleCategoryTop }}</a>
          </template>
          > {{ title }}
        </p>

        <!-- section / -->

        <section class="newslist fade-up">
          <article>
            <div v-for="post in posts" :key="post.id" class="box">
              <a :href="`${pathDetail}?id=${post.id}`">
                <div v-if="isEventCategory" class="thum">
                  <img :src="catchImage(post)" :alt="post.title.rendered" />
                </div>
                <p class="days">
                  <span :class="post.cat.slug">{{ post.cat.name }}</span>
                  <template v-if="!isEventCategory">
                    {{ post.date | local_datetime }}
                  </template>
                </p>
                <h3>{{ post.title.rendered }}</h3>
                <template v-if="isEventCategory">
                  <p class="point_tit">ここがポイント</p>
                  <p class="point" v-html="post.acf.point"></p>
                  <p v-if="post.acf.meeting_date" class="date">{{ post.acf.meeting_date }}</p>
                </template>
                <template v-else>
                  <p class="read" v-html="toExcerpt(post)"></p>
                </template>
              </a>
            </div>
          </article>

          <link-archive />
        </section>
      </div>

      <link-area />
    </template>
  </div>
  <!-- #news -->
  <!-- InstanceEndEditable -->
</template>

<script>
import LinkArchive from '@/components/ja/LinkArchive';
import LinkArea from '@/components/ja/LinkArea';

import pageCategories from '@/constants/pageCategories';
import titles from '@/constants/titles';

export default {
  components: {
    LinkArchive,
    LinkArea,
  },
  watch: {
    $route() {
      this.init();
    },
    title() {
      this.$emit('updateHead');
    },
  },
  mounted() {
    this.init();
  },
  updated() {
    // jQuery との統合
    const options = {
      byRow: false,
    };
    global.$('#e-list .newslist .box h3').matchHeight(options);
    global.$('#e-list .newslist .box .point').matchHeight(options);
    global.$('#news .box').matchHeight(options);
  },
  methods: {
    fetchData: function () {
      this.reloadPosts(this.page_categories, null, this.getQuery(this.$route.query.year));
    },
    init() {
      this.fetchData();
    },
  },
  computed: {
    // event / news の別
    pageTopCategory() {
      if (this.isEventCategory) {
        return pageCategories.event;
      }
      return pageCategories.news;
    },
    pageSubCategory() {
      const path = this.$route.path;
      if (path.includes('/event/upcoming')) {
        return pageCategories.upcoming;
      } else if (path.includes('/event/ondemand')) {
        return pageCategories.ondemand;
      } else if (path.includes('/event/partner')) {
        return pageCategories.partner;
      } else if (path.includes('/event/exhibition')) {
        return pageCategories.exhibition;
      } else if (path.includes('/event/pastevents')) {
        return pageCategories.pastevents;
      } else if (path.includes('/news/media.html')) {
        return pageCategories.media;
      } else if (path.includes('release.html')) {
        return pageCategories.release;
      }
      return undefined;
    },
    title() {
      return titles[this.pageSubCategory || this.pageTopCategory];
    },
    titleCategoryTop() {
      return titles[this.pageTopCategory];
    },
    isEventCategory() {
      return this.$route.path.indexOf('/event') === 0;
    },
    pathDetail() {
      return this.isEventCategory ? '/event/detail.html' : '/news/detail.html';
    },
    pathCategoryTop() {
      return this.isEventCategory ? '/event/' : '/news/index.html';
    },
    mainElementId() {
      return this.isEventCategory ? 'e-list' : 'news';
    },
  },
  head: {
    title() {
      // news 系はテンプレートで静的に title を設定済みのためイベント系のみ更新する
      if (this.isEventCategory) {
        return {
          inner: this.title,
        };
      }
    },
    link() {
      if (this.isEventCategory) {
        return [
          {
            rel: 'stylesheet',
            media: '(min-width: 768px)',
            href: '/css/event_pc.css',
          },
          {
            rel: 'stylesheet',
            media: '(max-width: 767px)',
            href: '/css/event_sp.css',
          },
        ];
      } else {
        return [
          {
            rel: 'stylesheet',
            media: '(min-width: 768px)',
            href: '/css/news_pc.css',
          },
          {
            rel: 'stylesheet',
            media: '(max-width: 767px)',
            href: '/css/news_sp.css',
          },
        ];
      }
    },
  },
};
</script>
