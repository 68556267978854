import Vue from 'vue';
import VueRouter from 'vue-router';
import TopIndexEn from '../components/en/views/TopIndex';
import ContentsIndexEn from '../components/en/views/ContentsIndex';
import ContentsDetailEn from '../components/en/views/ContentsDetail';
import TopIndexJp from '../components/ja/views/TopIndex';
import EventTopIndexJp from '../components/ja/views/EventTopIndex';
import ContentsIndexJp from '../components/ja/views/ContentsIndex';
import ContentsDetailJp from '../components/ja/views/ContentsDetail';

Vue.use(VueRouter);

const routes = [
  {
    path: '/en/news/detail.html',
    component: ContentsDetailEn,
  },
  {
    /**
     * /en/news
     * /en/news/
     * /en/news/index.html
     */
    path: '/en/news*',
    component: ContentsIndexEn,
  },
  {
    /**
     * /en/
     * /en/index.html
     */
    path: '/en/*',
    component: TopIndexEn,
  },
  /**
   * /event
   * /event/
   * /event/index.html
   */
  {
    path: '/event(/|/index.html)?',
    component: EventTopIndexJp,
  },
  {
    /**
     * /news/detail.html
     * /event/detail.html
     * /new-consumer/detail.html
     */
    path: '/*/detail.html',
    component: ContentsDetailJp,
  },
  /**
   * /
   * /index.html
   */
  {
    path: '/(index.html)?',
    component: TopIndexJp,
  },
  {
    /**
     * /news
     * /news/
     * /news/index.html
     * /news/media.html
     * /news/release.html
     * /news/news-release.html // alias path of /news/release.html
     * /event/upcoming
     * /event/ondemand
     * /event/partner
     * /event/pastevents
     */
    path: '*',
    component: ContentsIndexJp,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
